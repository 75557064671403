@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-family: var(--font-roboto);
}

main {
  background: #fafafa;
}
/* 
section {
  background: #FAFAFA;
}   */

#maxZindex {
  z-index: 9999 !important;
}

#spanTextWrap span {
  text-wrap: wrap !important;
}

.removeMarginFromH2 h2 {
  margin: 0 !important;
}

.removeMarginFromH2 button {
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}

/* third-party libraries CSS */
.testimonial-01 .swiper-pagination-bullet {
  @apply h-2.5 w-2.5 bg-stroke opacity-100;
}
.testimonial-01 .swiper-pagination-bullet-active {
  @apply w-5 rounded-full bg-primary;
}

.testimonial-01 .swiper-pagination {
  @apply relative pt-[50px];
}

#supportCheckbox:checked ~ div span {
  @apply opacity-100;
}

@layer base {
  body {
    @apply relative z-1 font-roboto text-regular font-normal text-waterloo dark:text-manatee;
  }
}

@layer components {
  .dropdown {
    @apply -left-7.5 mt-2 hidden min-w-max flex-col gap-4 rounded-md border border-stroke bg-white px-8 py-5 shadow-md duration-300 ease-in dark:border-strokedark dark:bg-black xl:invisible xl:absolute
    xl:mt-0 xl:flex xl:w-[250px] xl:translate-y-10 xl:opacity-0
    xl:before:absolute xl:before:-top-1.5 xl:before:left-10 xl:before:h-3 xl:before:w-3 xl:before:rotate-45 xl:before:border-l xl:before:border-t xl:before:border-stroke xl:before:bg-white xl:group-hover:visible xl:group-hover:translate-y-6.5 
    xl:group-hover:opacity-100 xl:dark:before:border-strokedark xl:dark:before:bg-black;
  }
}

@media (max-width: 1280px) {
  .navbar {
    @apply max-h-[400px] overflow-y-scroll;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .img-white {
    filter: invert(0%) sepia(96%) saturate(15%) hue-rotate(249deg) brightness(100%) contrast(105%);
  }
}

.blog-details-docs {
  @apply rounded-lg border border-white p-7.5 shadow-solid-3 transition-all dark:border-strokedark  dark:bg-blacksection xl:p-12.5;
}

.blog-details p {
  @apply mb-8 text-base leading-relaxed text-titlebgdark dark:text-waterloo;
}

.blog-details p strong {
  @apply text-primary dark:text-waterloo;
}
.blog-details h3 strong,
.blog-details h3 {
  @apply mb-10 text-xl !font-semibold leading-tight text-black dark:text-white sm:text-2xl sm:leading-tight lg:text-xl lg:leading-tight xl:text-2xl xl:leading-tight;
}

.blog-details h4 strong,
.blog-details h4 {
  @apply text-xl !font-semibold leading-tight text-black dark:text-white sm:text-2xl sm:leading-tight lg:text-xl lg:leading-tight xl:text-2xl xl:leading-tight;
}

.blog-details h5 strong,
.blog-details h5 {
  @apply mb-3 text-lg !font-semibold leading-tight text-black dark:text-white sm:text-xl;
}

.blog-details h1 {
  @apply mb-4 text-3xl !font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px] lg:text-4xl xl:text-[45px];
}
.blog-details h2 strong,
.blog-details h2 {
  @apply mb-4 text-[26px] !font-bold !leading-tight text-black dark:text-white sm:text-3xl md:text-4xl;
}

.blog-details ul,
.blog-details ol {
  @apply mb-10 list-inside list-disc text-titlebgdark;
}

.blog-details li,
.blog-details li {
  @apply mb-2 text-base text-titlebgdark dark:text-waterloo;
}

.blog-details blockquote {
  @apply relative z-10 mb-10 overflow-hidden rounded-sm bg-manatee p-8 text-center text-base font-medium italic text-black dark:bg-titlebgdark md:py-9 lg:px-10;
}

.blog-details blockquote::after {
  content: '';
  @apply absolute bottom-0 right-0 h-20 w-20 bg-[url(/images/blog/quote-shape-2.svg)] bg-contain bg-right-bottom bg-no-repeat;
}

.blog-details blockquote::before {
  content: '';
  @apply absolute left-0 top-0 h-[106px] w-[140px] bg-[url(/images/blog/quote-shape-1.svg)] bg-contain bg-left-top bg-no-repeat dark:bg-[url(/images/blog/quote-shape-1.svg)];
}

pre {
  @apply relative mb-2 rounded-lg bg-strokedark px-4 py-2;
}
.prism-copy-button {
  @apply absolute right-2 top-2 h-[26px] rounded bg-white px-4 text-primary;
}

.active-tab {
  @apply bg-stroke text-black dark:bg-blackho dark:text-white;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media (max-width: 600px) {
  .each-slide-effect {
    height: 200px;
  }
  .ui.segment {
    padding: 0 !important;
  }
}
@media (min-width: 600px) {
  .each-slide-effect {
    height: 250px;
  }
}
@media (min-width: 768px) {
  .each-slide-effect {
    height: 330px;
  }
}
@media (min-width: 1024px) {
  .each-slide-effect {
    height: 370px;
  }
}
@media (min-width: 1280px) {
  .each-slide-effect {
    height: 400px;
  }
}

.each-slide-effect {
  border-radius: 16px;
  overflow: hidden;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.custom-home-indicator {
  cursor: pointer;
  text-align: center;
  margin: 5px;
  background-color: #ebebeb;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.custom-home-indicator.active {
  background-color: #f7941d;
  width: 30px;
}
.custom-slider-container > div > .indicators {
  bottom: 2%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.custom-input-wrapper:focus-within {
  border-color: #f7941d !important;
}
.custom-select-trigger:focus {
  border-color: #e4e4e7 !important;
}
.custom-select-trigger:focus-within {
  border-color: #e4e4e7 !important;
}
.custom-select-trigger[data-open='true'] {
  border-color: #f7941d !important;
}
.custom-checkbox::after {
  background: transparent !important;
  transition-duration: 75ms !important;
}
.custom-checkbox > svg {
  color: #f7941d !important;
}
.custom-progress > .bar {
  background: #f7941d !important;
}
.custom-progress > .bar > .progress {
  font-size: 10px !important;
  font-weight: normal !important;
}
.custom-sidebar-base {
  border-radius: 0.75rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 500px);
  overflow: auto;
}

.notification {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
}
.notification .content {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.notification .content p.desc {
  margin: 0;
  color: #212529;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.notification .nofi-time {
  padding: 8px 10px;
  align-self: center;
  border-radius: 100px;
  color: #555;
}
.notification .nofi-time-end {
  padding: 8px 10px;
  border-radius: 100px;
  color: #555;
}
.notification .docImg {
  align-self: center;
  padding: 16px;
  border-radius: 28px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%),
    #f7941d;
}
.notification.unseen .docImg {
  background-color: #f7941d;
}
.notification.unseen .nofi-time {
  background-color: #f7941d;
  color: white;
}
.notification.unseen .nofi-time-end {
  background-color: #f7941d;
  color: white;
}
.notification.seen .docImg {
  background-color: gray;
}
.notification.seen .docImg img {
  filter: grayscale(100%);
}
.notification.seen .content p.desc {
  color: gray;
}
.image-gallery-thumbnail-image {
  height: 100px !important;
  object-fit: contain !important;
}
.image-gallery-image {
  height: 400px !important;
}
.otp-wrapper > div {
  gap: 15px;
  width: 100%;
  margin: 2rem auto;
}

.otp-wrapper input {
  flex: 1;
  height: 40px;
  width: 40px !important;
  border-radius: 8px;
  border: 2px solid black;
  transition: all 0.3s ease-in-out;
  color: black;
  font-weight: 700;
  font-size: 1rem;
}

.otp-wrapper input:focus {
  border-color: #555 !important;
}
.special-service:hover svg path {
  fill: white;
}
.active-icon path {
  fill: white;
}
.custom-toast-container {
  position: absolute;
  top: 500px;
  right: 0;
  left: auto;
  z-index: 9999;
}
